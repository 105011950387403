@font-face {
  font-family: "Acknowledgement";
  src: url(/public/fonts/Acknowledgement.otf);
}

@font-face {
  font-family: "MrDafoe";
  src: url(/public/fonts/MrDafoe-Regular.ttf);
}

@font-face {
  font-family: "Nbr-Font";
  src: url(/public/fonts/NotesStyleNbr-Brand.ttf);
}

:root {
  --scrollBarWidth: 5px;
  --scrollBarHeight: 5px;
  font-size: 16px;
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}

/* Für Firefox */
* {
  scrollbar-width: thin;
  /* oder 'auto' oder 'none' */
  scrollbar-color: grey rgba(0, 0, 0, 0.3);
  /* Daumen und Bahn */
}

::-webkit-scrollbar {
  width: var(--scrollBarWidth);
  height: var(--scrollBarHeight);
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, .3);
}

html,
body {
  overscroll-behavior: none;
  font-size: 1rem;
}

body {
  --400ms: 400ms;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*THIS AVOIDS text BEEING ZOOMED ON ORIENTATION-CHANGE FOR. The solution is from https://www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
  -webkit-text-size-adjust: 100%;
  
  word-break: keep-all;
}

h1 {
  font-size: 2em;
}

button, input, select, textarea {
  outline: none;
}

button {
  border: none;
  border-radius: 5px;
}

.default_DriveBy_Button {
  background-color: black;
  color: white !important;
  text-decoration: none;
  letter-spacing: 0.5px;
  transition: var(--400ms) ease;
}

.default_DriveBy_Button:not([disabled]):hover {
  background-color: gray;
  border-radius: 3px;
  letter-spacing: 0.5px;
  color: white !important;
}

button:not([disabled]):hover {
  cursor: pointer;
}

form>*:not(.generalCard > *):not(:last-child):not(button) {
  margin: 5px 0;
}

label {
  display: inline-block;
}

.homePageFormular select {
  background-color: transparent;
}

/*This avoids the automatic zoom-in on iOS devices when a input field is selected*/
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  font-size: 1rem;
}

input,
select,
textarea {
  padding: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 5px;
}

input,
select {
  max-height: 41px;
}

textarea {
  height: 219px;
  resize: vertical;
}

/*This style declaration is dedicated to iOS-devices where select-elements are styled horrible by the browser itself*/
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: 98% 70%;
  background-repeat: no-repeat;
  background-color: white;
  color: black;
}

a {
  color: black;
  text-decoration: none;
}

.d_none {
  display: none;
}

.d_block {
  display: block;
}

.d_inline_block {
  display: inline-block;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100% !important;
}

.w_auto {
  width: auto!important;
}

.max_Height_70 {
  max-height: 70%;
}
.max_Height_100 {
  max-height: 100%;
}
.text_left {
  text-align: left !important;
}

.text_center {
  text-align: center !important;
}

.text_right {
  text-align: right !important;
}

.img_fluid {
  max-width: 100%;
}

.img_fill_width {
  width: 100%;
}

.img_fill_height {
  height: 100%;
}

.img_fit_cover {
  object-fit: cover;
}

.img_icon {
  max-width: 80px;
}

.img_icon_small {
  max-width: 60px;
}

.fa { transform: scale(2,2); }
.image_Container {
  position: relative;
}
.image_Container::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%; /*56.25%; Ersetzen Sie Höhe und Breite mit Ihrem gewünschten Verhältnis */
}
.image_Container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image_Profile_Container {
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.image_Profile_Container .verified_Icon {
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 80%;
  left: 80%;
  transform: translate(-80%, -80%);
}
.generalCard {
  border: 1px solid #ccc;
  overflow-y: auto;
}
.white_blue {
  background-color: rgb(238, 248, 255);
}

.max_800 {
  max-width: 800px;
  margin: 0 auto;
}

.max_900 {
  max-width: 900px;
}

.allianz_logo {
  max-width: 200px;
}

.lba_logo,
.easa_logo {
  max-width: 100px;
}

.overflow-hidden {
  /*position relative and height: 100% is needed so it works also on iOS-decive, as well as -webkit-overflow-scrolling: touch;*/
  position: relative;
  overflow: hidden;
  touch-action: none;
  /* -webkit-overflow-scrolling: touch; */
  height: 100vh;
}

.overflow-hidden::-webkit-scrollbar {
  display: none;
}

.small_Font {
  font-size: 0.8rem;
}
.transbox {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(4px);
}

.lead {
  font-size: 1.1rem;
  font-weight: 300;
}

.text_as_Button {
  cursor: pointer;
}

.tooltip {
  position: relative;
}
.tooltip_Container {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  top: 100%;
  left: -190%;
  /* transform: translateX(-30%); */
  opacity: 0;
  transition: opacity 500ms;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, .5);
}
.tooltip:hover .tooltip_Container {
  z-index: 20;
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltip_Text {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  transition: background-color 500ms;
  font-size: 0.8rem;
}
.tooltip .tooltip_Text:hover {
  background-color: gray;
}

/*CSS Style for styling Colors and Backrounds*/
.gray_Font {
  color: #8a9194;
}
.bg_white {
  background-color: white;
}

/*THis CSS Below is for displaying Errors to the user*/
.failure {
  color: red;
  font-weight: bold;
}
.success {
  color: lightgreen;
  font-weight: bold;
}
.input_error {
  border: 2px solid red;
}
.text_error {
  color: red;
}
.text_success {
  color: 	#6cc070;
}
.text_reminder {
  color: orange;
}
.background_success {
  background-color: #6cc070;
}
.background_reminder {
  background-color: orange;
}
.background_error {
  background-color: red;
}
.text_linethrough {
  text-decoration: line-through;
}
.list_Style_Hyphen {
  list-style-type: disc;
}
.text_upperCase {
  text-transform: uppercase;
}
.driveBy_Brand_Font_WWW {
  font-family: 'Acknowledgement', sans-serif;
  font-size: 0.8rem;
}
.home_Page_Headline {
  font-size: 2.5rem;
  font-weight: 100;
  font-family: "MrDafoe";
}
.home_Page_Subheadlines {
  font-size: 2.3rem;
  font-weight: 100;
  font-family: "Nbr-Font";
}

/* The Modal (background). Those two CSS Classes are used for any modal feature (signup, login, payment etc...) */
.modal {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 200;
  /* Sit on top 
  padding-top: 100px; 
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Height 100%, becasueof the Browser-Tab-Menu, that appears and disappears on toch-devices  */
  height: 100%;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  /* Black w/ opacity */
  color: #f1f1f1;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  animation-duration: 400ms;
  animation-iteration-count: 1;
}
.modal.display {
  display: flex;
}